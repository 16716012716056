<template>
    <div class="d-flex flex-wrap">
        <div class="col-12 mb-3">
            <h1 class="h2">
                {{ itemComponent.product.name }}
                en
                <strong class="text-primary text-capitalize">
                    {{ itemComponent.merchant.type | merchantInfo("type") }}
                    {{ itemComponent.merchant.name }}
                </strong>
            </h1>
        </div>

        <div
            class="col-12 mb-3 d-flex justify-content-center align-items-center"
        >
            <vs-button circle icon floating @click="getBaseItem">
                <reload-icon />
            </vs-button>
            <tooltip-button
                v-if="
                    $enabledEcommerceOptions &&
                    $ability.hasScope('inventory:Active')
                "
                :icon="itemComponent.isEnabled ? '🔴' : '🟢'"
                :message="
                    itemComponent.isEnabled
                        ? 'Desactivar inventario'
                        : 'Activar inventario'
                "
                @click="openActiveModal"
            />
            <tooltip-button
                v-if="$ability.hasScope('inventory:Delete')"
                icon="💀"
                message="Eliminar inventario"
                @click="deleteInventoryComponent"
            />
            <vs-switch
                v-if="$ability.hasScope('inventory:Update') && id"
                v-model="enabledUpdate"
                :class="enabledUpdate ? 'bg-primary' : 'bg-secondary'"
            >
                🖊
            </vs-switch>
        </div>

        <div
            class="col-lg-2 col-md-3 col-4 d-flex justify-content-center align-items-center py-2"
        >
            <button
                class="w-28 h-28 p-0"
                @click="goToMainPage(itemComponent.product.slug)"
            >
                <img
                    class="w-100 h-100"
                    :src="itemComponent.images[0].url | image"
                    :alt="itemComponent.images[0].alt"
                    loading="lazy"
                />
            </button>
        </div>

        <div class="col-lg-10 col-md-9 col-8 d-flex flex-wrap py-2">
            <div class="d-flex flex-column col-lg-4 col-12 col-md-6">
                <strong class="">Color </strong>
                <div class="d-flex align-items-center">
                    <small class="text-capitalize">
                        {{ itemComponent.color }}
                    </small>
                    <div
                        class="ml-1 len-3 rounded-circle border border-secondary"
                        :style="{
                            'background-color': itemComponent.colorRef
                        }"
                    ></div>
                </div>
            </div>

            <div class="d-flex flex-column col-lg-4 col-12 col-md-6">
                <strong class="">Tamaño/Talla/Valor</strong>
                <small class="text-capitalize">
                    {{ itemComponent.size }}
                </small>
            </div>

            <div class="d-flex flex-column col-lg-4 col-12 col-md-6">
                <strong class="">Enlaces </strong>
                <small>
                    Prod
                    <router-link
                        :to="`/app/products/detail/${itemComponent.product.id}`"
                        class="btn-a p-0"
                    >
                        {{ itemComponent.product.sku }} ({{
                            itemComponent.product.id
                        }})
                    </router-link>

                    | Ref
                    <router-link
                        :to="`/app/products/detail/${itemComponent.product.id}reference=${itemComponent.id}&active=references`"
                        class="btn-a p-0"
                    >
                        {{ itemComponent.barcode }}
                        ({{ itemComponent.id }})
                    </router-link>
                </small>
            </div>

            <div class="d-flex flex-column col-lg-4 col-12 col-md-6">
                <strong class="">Stock </strong>
                <small>
                    {{ itemComponent.stockMerchant }}
                </small>
            </div>

            <div class="d-flex flex-column col-lg-4 col-12 col-md-6">
                <strong class="">Margen actual</strong>

                <small>
                    {{ revenue | percent }}
                </small>
            </div>

            <div class="d-flex flex-column col-lg-4 col-12 col-md-6">
                <strong class="">Precios {{ $siteType }} </strong>
                <small>
                    {{ itemComponent.priceOffer | money }} /
                    {{ itemComponent.price | money }}
                </small>
            </div>
            <div
                v-if="$enabledEcommerceOptions"
                class="d-flex flex-column col-lg-4 col-12 col-md-6"
            >
                <strong class="">Inventario activo </strong>
                <small>{{ itemComponent.isEnabled ? "Si 🟢" : "No" }} </small>
            </div>
            <fragment
                v-if="
                    itemComponent.isEnabled && itemComponent.priceAgreedMerchant
                "
            >
                <div class="d-flex flex-column col-lg-4 col-12 col-md-6">
                    <strong class="">Precio acuerdo marketplace </strong>
                    <small>
                        {{ itemComponent.priceAgreedMerchant | money }}
                    </small>
                </div>
                <div class="d-flex flex-column col-lg-4 col-12 col-md-6">
                    <strong class=""> Margen por venta en marketplace </strong>
                    <small>
                        {{ revenueMarketplace | percent }}
                    </small>
                </div>
            </fragment>
        </div>

        <div class="col-12 col-md-6 mt-5">
            <input-money-component
                v-model="itemComponent.priceMerchant"
                border
                :enabled="enabledUpdate"
                label="Precio sin descuento comercio"
                class="opacity-false"
                icon="💲"
                :message-error="
                    introValidators.priceMerchant &&
                    !itemComponent.priceMerchant
                        ? 'El precio sin descuento comercio no puede ser cero'
                        : ''
                "
                @blur="introValidators.priceMerchant = true"
            />
        </div>

        <div class="col-12 col-md-6 mt-5">
            <input-money-component
                v-model="itemComponent.priceOfferMerchant"
                border
                :enabled="enabledUpdate"
                label="Precio final comercio"
                class="opacity-false"
                icon="💲"
                :message-error="
                    itemComponent.priceOfferMerchant >
                    itemComponent.priceMerchant
                        ? 'El precio final comercio debe ser menor que el precio'
                        : introValidators.priceOfferMerchant &&
                          !itemComponent.priceOfferMerchant
                        ? 'El precio final comercio no puede ser cero'
                        : ''
                "
                @blur="onChangePriceOfferMerchantChange"
            />
        </div>

        <div class="col-12 col-md-6 mt-5">
            <input-money-component
                v-model="itemComponent.costMerchant"
                border
                :enabled="enabledUpdate"
                label="Costo en comercio"
                class="opacity-false"
                icon="💲"
                :message-error="
                    introValidators.costMerchant && !itemComponent.costMerchant
                        ? 'El costo en comercio no puede ser cero'
                        : ''
                "
                @blur="onChangeCostMerchantChange"
            />
        </div>

        <div class="col-12 col-md-6 mt-5">
            <vs-input
                v-model="itemComponent.stockMerchant"
                border
                shadow
                type="number"
                min="0"
                label="Stock en comercio"
                class="opacity-false"
                :state="stateEnabled"
                :disabled="!enabledUpdate"
                @blur="introValidators.stockMerchant = true"
            >
            </vs-input>
        </div>

        <div class="col-12 col-md-6 mt-5">
            <vs-input
                v-model="itemComponent.maxStockMerchant"
                label="Stock máximo en comercio"
                class="opacity-false"
                type="number"
                min="1"
                :state="stateEnabled"
                border
                shadow
                :disabled="!enabledUpdate"
            />
        </div>

        <div class="col-12 col-md-6 mt-5">
            <vs-input
                v-model="itemComponent.minStockMerchant"
                border
                :state="stateEnabled"
                shadow
                class="opacity-false"
                min="0"
                type="number"
                label="Stock mínimo en comercio"
                :disabled="!enabledUpdate"
            />
        </div>

        <div class="col-12 col-md-6 mt-5">
            <checkbox-selector
                v-model="itemComponent.alwaysStockMerchant"
                label="Siempre en inventario"
                :enabled="enabledUpdate"
            />
        </div>

        <div class="col-12 d-flex justify-content-center my-5">
            <vs-button
                v-if="$ability.hasScope('inventory:Update') && enabledUpdate"
                :loading="isLoading"
                @click="updateInventoryComponent"
            >
                Actualizar inventario
            </vs-button>
        </div>

        <vs-dialog v-model="isActiveModal" auto-width>
            <template #header>
                <h2>
                    Deseas
                    {{ itemComponent.isEnabled ? "desactivar" : "activar" }}
                    el inventario ?
                </h2>
            </template>
            <div class="mx-2">
                <div v-if="itemComponent.isEnabled" class="col-10 mx-auto">
                    <span>
                        Vas a desactivar el inventario de la referencia ({{
                            itemComponent.id
                        }}) para el comercio {{ itemComponent.merchant.name }}.
                        Ahora NO se podrá comprar el inventario del comercio
                        desde el {{ $siteType }}.
                    </span>
                </div>
                <div v-else>
                    <span>
                        Vas a activar el inventario de la referencia ({{
                            itemComponent.id
                        }}) para v-else comercio
                        {{ itemComponent.merchant.name }}. Ahora se podrá
                        comprar el inventario del comercio desde el
                        {{ $siteType }}. Por favor verifica el precio que el
                        comercio le ofrece al {{ $siteType }}, para que el
                        {{ $siteType }} tenga margen. El precio que le ofrece el
                        comercio debe ser menor que el precio de venta en el
                        {{ $siteType }} ({{
                            itemComponent.priceOffer | currency
                        }}) Recomendado ({{
                            $data.$suggestMarginMarketplace | percent
                        }}
                        ) del precio actual
                    </span>
                    <input-money-component
                        v-model="itemComponent.priceAgreedMerchant"
                        border
                        enabled
                        class="mt-4"
                        :label="`Precio acuerdo ${$siteType}`"
                        icon="💲"
                        :message-error="
                            !isValidPriceAgreedMerchant
                                ? 'El precio acordado no puede ser mayor al precio final actual ni puede ser 0'
                                : ''
                        "
                    />
                </div>
                <div class="mt-3 d-flex justify-content-center">
                    <vs-button
                        :disable="
                            !isValidPriceAgreedMerchant &&
                            !itemComponent.isEnabled
                        "
                        @click="toggleActiveInventory"
                    >
                        {{ itemComponent.isEnabled ? "Desactivar" : "Activar" }}
                    </vs-button>
                </div>
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import {
    DEFAULT_IMAGE_URL,
    DEFAULT_MARKETPLACE_MARGIN
} from "@/common/constants";

import ReloadIcon from "@/components/icons/ReloadIcon.vue";
import CheckboxSelector from "@/components/utils/CheckboxSelector.vue";
import InputMoneyComponent from "@/components/utils/InputMoneyComponent.vue";
import TooltipButton from "@/components/utils/TooltipButton.vue";

export default {
    name: "DetailInventory",
    components: {
        ReloadIcon,
        InputMoneyComponent,
        CheckboxSelector,
        TooltipButton
    },
    data: () => ({
        id: null,
        $suggestMarginMarketplace: 1 - DEFAULT_MARKETPLACE_MARGIN / 100,
        revenue: 0,
        revenueMarketplace: 0,
        isLoading: false,
        loaderInstance: null,
        enabledUpdate: false,
        isActiveModal: false,
        introValidators: {
            stockMerchant: false,
            priceOfferMerchant: false,
            priceMerchant: false,
            costMerchant: false
        },
        merchant: { merchantId: "", merchantType: "" },
        itemComponent: {
            priceOffer: 1000,
            priceAgreedMerchant: 0,
            price: 1000,
            id: 1,
            size: "Unico",
            color: "blanco",
            colorRef: "#fff",
            stock: 1,
            barcode: "",
            merchant: {
                id: "",
                name: ""
            },
            product: {
                id: 1,
                name: "Cargando",
                slug: "cargando"
            },
            images: [
                {
                    id: 1,
                    url: DEFAULT_IMAGE_URL,
                    alt: "alt"
                }
            ],
            isEnabled: false,
            costMerchant: 700,
            maxStockMerchant: 100,
            minStockMerchant: 0,
            priceOfferMerchant: 1000,
            priceMerchant: 1000,
            stockMerchant: 0,
            alwaysStockMerchant: false
        }
    }),
    computed: {
        ...mapState("commons", ["selectedMerchant"]),
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        stateEnabled() {
            return this.enabledUpdate ? this.stateInputDark : "";
        },
        isValidPriceAgreedMerchant() {
            return (
                this.itemComponent.priceAgreedMerchant <=
                    this.itemComponent.priceOffer &&
                this.itemComponent.priceAgreedMerchant
            );
        }
    },
    watch: {
        selectedMerchant(value) {
            if (this.id && !this.isLoading) {
                this.merchant = value;
                this.getBaseItem();
            }
        }
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("inventory:Get", "/app/inventories")))
            return;
        const { id, merchantId, merchantType } = this.$route.params;
        this.id = parseInt(id);
        if (merchantId && merchantType) {
            this.merchant = { merchantId, merchantType };
        } else {
            this.merchant = this.selectedMerchant;
        }
        this.getBaseItem();
    },
    methods: {
        ...mapActions("inventories", [
            "getInventory",
            "updateInventory",
            "deleteInventory",
            "activeInventoryReference",
            "listInventoryByReference"
        ]),
        async getBaseItem() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square-rotate" });
            try {
                this.itemComponent = await this.getInventory({
                    referenceId: this.id,
                    merchantId: this.merchant.merchantId,
                    merchantType: this.merchant.merchantType
                });
                this.calculateRevenue();
            } catch (error) {
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async updateInventoryComponent() {
            if (!this.validateInventory()) {
                return;
            }
            this.isLoading = true;
            try {
                const {
                    alwaysStockMerchant,
                    costMerchant,
                    id,
                    maxStockMerchant,
                    minStockMerchant,
                    priceMerchant,
                    priceOfferMerchant,
                    stockMerchant
                } = this.itemComponent;
                await this.updateInventory({
                    merchantId: this.itemComponent.merchant.id,
                    merchantType: this.itemComponent.merchant.type,
                    referenceId: id,
                    stockMerchant,
                    maxStockMerchant,
                    minStockMerchant,
                    priceOfferMerchant,
                    costMerchant,
                    priceMerchant,
                    alwaysStockMerchant
                });

                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        async deleteInventoryComponent() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: "Vas a eliminar una referencia de tu inventario ?",
                text: `La referencia no volverá a aparecer en la vista de inventario y virtualmente tendrás inventario en 0 para la referencia ${this.itemComponent.id}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33ddd",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            });
            if (dialogResult.isConfirmed) {
                this.isLoading = true;
                this.loaderInstance = this.$vs.loading({ type: "circles" });
                try {
                    await this.deleteInventory({
                        referenceId: this.itemComponent.id,
                        merchantType: this.itemComponent.merchantType,
                        merchantId: this.itemComponent.merchantId
                    });

                    this.itemComponent = {
                        ...this.itemComponent,
                        stockMerchant: 0,
                        isEnabled: false,
                        maxStockMerchant: 0,
                        minStockMerchant: 0,
                        costMerchant: 0,
                        priceMerchant: 0,
                        priceOfferMerchant: 0
                    };
                    this.loaderInstance.close();
                    this.isLoading = false;
                    this.$router.push("/app/inventories");
                } catch (error) {
                    this.loaderInstance.close();
                    this.isLoading = false;
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: error.title,
                        text: error.message,
                        icon: error.icon
                    });
                    const sound = this.$sounds.get("error");
                    sound.volume(0.9);
                    sound.play();
                }
            }
        },
        async toggleActiveInventory() {
            this.isActiveModal = false;
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({
                type: "square-rotate"
            });
            try {
                await this.activeInventoryReference({
                    referenceId: this.itemComponent.id,
                    merchantId: this.itemComponent.merchant.id,
                    merchantType: this.itemComponent.merchant.type,
                    isEnabled: !this.itemComponent.isEnabled,
                    priceAgreedMerchant: this.itemComponent.priceAgreedMerchant
                });
                this.itemComponent.isEnabled = !this.itemComponent.isEnabled;
                this.calculateRevenue();
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.calculateRevenue();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        openActiveModal() {
            if (!this.itemComponent.priceAgreedMerchant) {
                this.itemComponent.priceAgreedMerchant =
                    this.itemComponent.priceOffer *
                    this.$data.$suggestMarginMarketplace;
            }
            this.isActiveModal = true;
        },
        validateInventory() {
            this.introValidators = {
                stockMerchant: true,
                priceOfferMerchant: true,
                priceMerchant: true,
                costMerchant: true
            };
            if (
                this.itemComponent.stockMerchant < 0 ||
                this.itemComponent.maxStockMerchant < 1 ||
                this.itemComponent.minStockMerchant < 0 ||
                !this.itemComponent.priceOfferMerchant ||
                !this.itemComponent.priceMerchant ||
                !this.itemComponent.costMerchant ||
                this.itemComponent.priceOfferMerchant >
                    this.itemComponent.priceMerchant
            ) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Inventario invalido",
                    text: "Revisa por favor que todo este completo",
                    icon: "info"
                });
                return false;
            }
            return true;
        },
        goToMainPage(slug) {
            window.open(`${this.$marketplaceUrl}/${slug}`, "_blank").focus();
        },
        onChangePriceOfferMerchantChange() {
            this.introValidators.priceOfferMerchant = true;
            this.calculateRevenue();
        },
        onChangeCostMerchantChange() {
            this.introValidators.costMerchant = true;
            this.calculateRevenue();
        },
        calculateRevenue() {
            this.revenue =
                1 -
                this.itemComponent.costMerchant /
                    this.itemComponent.priceOfferMerchant;
            if (this.itemComponent.priceAgreedMerchant) {
                this.revenueMarketplace =
                    1 -
                    this.itemComponent.costMerchant /
                        this.itemComponent.priceAgreedMerchant;
            }
        }
    }
};
</script>
